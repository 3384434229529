import "./PlanScreen.css"
import "./OnboardingScreen.css"
import requests from "../api/requests"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getUser } from "../app/features/userSlice"
import { useNavigate } from "react-router-dom"
import Button from "../components/Button"
import { useSnackbar } from "react-simple-snackbar"
import { DEFAULT_ERROR_MESSAGE, errorSnackBar } from "../components/snackBarOptions"
import { Skeleton } from "@mui/material"

function PlanScreen() {
  const user = useSelector(getUser)
  const [plans, setPlans] = useState([])
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [openErrorSnackbar] = useSnackbar(errorSnackBar)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  if (!user) {
    navigate("/")
  }

  useEffect(() => {
    const fecthPlans = async () => {
      setLoading(true)
      
      try {
        const subscriptions = await requests.getUserSubscription()
        if (subscriptions?.data?.status) {
          navigate("/")
          return
        }
      } catch (error) {
        console.log("not subscribed")
      }

      try {
        const respPlans = await requests.getPlans()
        setPlans(respPlans.data)
        if (respPlans.data.length > 0) {
          setSelectedPlan(respPlans.data[0])
        }
      } catch (error) {
        openErrorSnackbar(DEFAULT_ERROR_MESSAGE)
      }

      setLoading(false)
      return
    }

    fecthPlans()
  }, [])

  const loadCheckout = async (priceID) => {
    let resp = null

    try {
      resp = await requests.createCheckoutSession({
        priceID: priceID,
        cancelURL: window.location.origin,
        successURL: window.location.origin,
      })

      window.location.href = resp?.data?.url
    } catch (error) {
      openErrorSnackbar(DEFAULT_ERROR_MESSAGE)
    }
  }

  const formatPrice = (amount) => {
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    })

    return formatter.format(amount)
  }

  const formatInterval = (interval) => {
    if (interval === "month") {
      interval = "mês"
    }
    if (interval === "year") {
      interval =  "ano"
    }

    if (isMobile)
      return "/" + interval

    return "por " + interval
  }

  const handlePlanClick = (plan) => {
    setSelectedPlan(plan)
  }

  const isPlanSelected = (plan) => {
    return selectedPlan?.id === plan.id
  }

  const handleCheckout = async () => {
    if (!selectedPlan) {
      openErrorSnackbar("Selecione um plano")
      return
    }

    setIsLoadingCheckout(true)
    await loadCheckout(selectedPlan.priceID)
    setIsLoadingCheckout(false)
  }

  const isMobile = window.innerWidth < 768
  return (
    <div className="onboardingScreen">
      <div className="onboardingScreen__background"></div>
      <div className="onboardingScreen__gradient"></div>
      <div className="onboardingScreen__body">
        <div className="planScreen">
          <h1>Estamos quase lá</h1>
          <h2>Confira os detalhes do seu plano</h2>
          {!loading ? plans.map((plan) => (
            <div
              key={plan.id}
              onClick={(e) => {
                handlePlanClick(plan)
              }}
              className={
                isPlanSelected(plan)
                  ? "planScreen__plan__selected"
                  : "planScreen__plan"
              }
            >
              <div className="planScreen__plan__body">
                <h2>{plan.name}</h2>
                {!isMobile ? <p>{plan.description}</p> : null}
              </div>
              <div className="planScreen__plan__price">
                <h3>{formatPrice(plan.amount)}</h3>
                <p>{formatInterval(plan.recurrentInterval)}</p>
              </div>
            </div>
          )) : (
            <div>
              <Skeleton height={120} animation="wave" />
              <Skeleton height={120} animation="wave" />
            </div>
          )}
          <Button
            onClick={(e) => handleCheckout()}
            text="Ir para a página de pagamento"
            isLoading={isLoadingCheckout}
          />
        </div>
      </div>
    </div>
  )
}

export default PlanScreen
