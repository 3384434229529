import SerieDesktop from "../components/series/SerieDesktop.js"
import SerieMobile from "../components/series/SerieMobile.js"
import Nav from "../components/Nav.js";

function SerieScreen() {
  const isMobile = window.innerWidth < 1090

  return (
    <div>
      <Nav />
      {isMobile ? <SerieMobile /> : <SerieDesktop />}
    </div>
  )
}

export default SerieScreen
