import React, { useEffect, useState } from "react"
import FormInput from "../components/FormInput"
import Button from "../components/Button"
import { useSnackbar } from "react-simple-snackbar"
import { DEFAULT_ERROR_MESSAGE, errorSnackBar } from "../components/snackBarOptions"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { login } from "../app/features/userSlice"
import requests from "../api/requests"

import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'

export default function SignInScreen() {
  const [openErrorSnackbar] = useSnackbar(errorSnackBar)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const location = useLocation()
  const content = location?.state

  useEffect(() => {
    if (!content?.email) {
      navigate("/")
    }
  }, [])

  const [values, setValues] = useState({
    email: content?.email,
    password: "",
  })

  const [isLoading, setIsLoading] = useState(false)

  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "Preencha com um email válido",
      label: "Email",
      required: true,
      autoComplete: false,
      icon: faEnvelope,
    },
    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: "Senha",
      errorMessage: "Preecha com sua senha",
      label: "Senha",
      required: true,
      autoFocus: true,
      icon: faLock,
    },
  ]

  const signIn = async (e) => {
    e.preventDefault()
    const formElement = e.target
    const isValid = formElement.checkValidity()
    if (!isValid) {
      openErrorSnackbar("Preencha os campos solicitados")
      return
    }

    setIsLoading(true)
    try {
      const resp = await requests.login({
        email: values.email,
        password: values.password,
      })
      dispatch(login(resp.data))
      navigate("/")
    } catch (error) {
      if (error?.response?.status === 400) {
        openErrorSnackbar("Email ou senha incorretos")
        return
      }
      openErrorSnackbar(DEFAULT_ERROR_MESSAGE)
    } finally {
      setIsLoading(false)
    }
  }

  const recoverPassword = async (e) => {
    e.preventDefault()
    navigate("/recover-password", { state: { email: values.email } })
  }

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  return (
    <div className="onboardingScreen">
      <div className="onboardingScreen__background"></div>
      <div className="onboardingScreen__gradient"></div>
      <div className="onboardingScreen__body">
        <div className="onboardingScreen__form">
          <h1>Olá {content?.name}!</h1>
          <h2>Preencha com sua senha para entrar</h2>
          <form onSubmit={signIn} noValidate autoComplete="off">
            {inputs.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            <h5>
              <p className="singupScreen__link" onClick={recoverPassword}>
                Esqueceu sua senha? Clique aqui!
              </p>
            </h5>
            <Button text="Entrar" isLoading={isLoading} />
          </form>
        </div>
      </div>
    </div>
  )
}
