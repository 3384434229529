import React, { useMemo, useState } from "react"
import "./Banner.css"
// import { useNavigate } from "react-router-dom"

function Banner({ episodes }) {
  // const [episode, setEpisode] = useState({})
  const [currentImage] = useState(0)

  const baseUrl = process.env.PUBLIC_URL
  const images = useMemo(() => [
    `${baseUrl}/banner.png`,
  ], [baseUrl])

  // const navigate = useNavigate()

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentImage(Math.floor(Math.random() * images.length))
  //   }, 5000)

  //   return () => clearInterval(interval)
  // }, [images])

  // useEffect(() => {
  //   const randomIndex = Math.floor(Math.random() * episodes.length)
  //   setEpisode(episodes[randomIndex])
  // }, [episodes])

  // const truncate = (text, maxCharacters) => {
  //   return text?.lenght > maxCharacters
  //     ? `${text.substr(0, maxCharacters - 1)}...`
  //     : text
  // }

  // const baseURL = process.env.REACT_APP_CMS_URL

  // const handleImageClick = (content) => {
  //   navigate(`/watch`, { state: { content } })
  // }

  return (
    <header
      className="banner"
      style={{
        backgroundSize: "cover",
        backgroundImage: currentImage
          ? `url(${images[currentImage]})`
          : `url(${images[0]})`,
        backgroundPosition: "center center",
      }}
    >
      <div className="banner__contents">
        <h1 className="banner__title"> </h1>
        <div className="banner__buttons">
          {/* <button
            // onClick={() => handleImageClick(episode)}
            className="banner__button"
          >
            Assistir
          </button> */}
        </div>
        <h1 className="banner__description">
          {/* {truncate(episode?.description, 150)} */}
        </h1>
      </div>

      <div className="banner--fadeBottom" />
    </header>
  )
}

export default Banner
