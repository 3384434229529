import React, { useEffect, useRef, useState } from "react"
import "./Nav.css"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { getUser } from "../app/features/userSlice"
import { Drawer } from "@mui/material"
import DehazeIcon from "@mui/icons-material/Dehaze"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSun, faWallet, faLock } from "@fortawesome/free-solid-svg-icons"

import Sumary from "./Sumary"
import requests from "../api/requests"
import { errorSnackBar } from "./snackBarOptions"
import { useSnackbar } from "react-simple-snackbar"

function Nav({
  sections = [],
  scrollFunc = function () {},
  hideSumary = true,
}) {
  const [show, handleShow] = useState(false)
  const [openSumary, setOpenSumary] = useState(false)
  const [openErrorSnackbar] = useSnackbar(errorSnackBar)

  const navigate = useNavigate()
  const user = useSelector(getUser)
  const transitionNavBar = () => {
    if (window.scrollY > 80) {
      handleShow(true)
    } else {
      handleShow(false)
    }
  }

  const toggleDrawer = (newOpen) => () => {
    setOpenSumary(newOpen)
  }

  useEffect(() => {
    window.addEventListener("scroll", transitionNavBar)
    return () => window.addEventListener("scroll", transitionNavBar)
  }, [])

  const Avatar = () => {
    const dropDownRef = useRef()

    const [openDropDown, setOpenDropDown] = useState(false)
    const [isLoadingPortal, setIsLoadingPortal] = useState(false)

    const handleDropDown = () => {
      setOpenDropDown(!openDropDown)
    }

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (!dropDownRef?.current?.contains(event.target)) {
          setOpenDropDown(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [dropDownRef])

    const loadPortal = (e) => {
      e.preventDefault()
      setIsLoadingPortal(true)
      setOpenDropDown(true)
      requests
        .createPortalSession({
          returnURL: window.location.origin,
        })
        .then((res) => {
          if (res?.data?.url) window.location.href = res.data.url
        })
        .catch((error) => {
          console.log(error)
          setIsLoadingPortal(false)
          openErrorSnackbar("Ocorreu um erro inexperado")
        })
    }

    return (
      <section ref={dropDownRef}>
        <img
          onClick={handleDropDown}
          className="nav__avatar"
          src={`https://ui-avatars.com/api/?name=${user?.name}&background=f2e1d1&size=128`}
          alt={user?.name}
        />
        {openDropDown && (
          <ul className="nav__dropdown">
            <li className="nav__dropdown__item">
              <button onClick={loadPortal}>
                {" "}
                {isLoadingPortal ? (
                  <FontAwesomeIcon icon={faSun} spin />
                ) : (
                  <FontAwesomeIcon icon={faWallet} />
                )}{" "}
                Gerenciar Assinatura
              </button>
            </li>
            <li className="nav__dropdown__item">
              <button onClick={() => navigate("/profile")}>
                {" "}
                <FontAwesomeIcon icon={faLock} /> Alterar Senha
              </button>
            </li>
          </ul>
        )}
      </section>
    )
  }

  return (
    <div className={`nav ${show && "nav__black"}`}>
      <div className="nav__contents">
        {hideSumary ? (
          <img
            onClick={() => navigate("/")}
            className="nav__logo"
            src={process.env.PUBLIC_URL + "/Selo_5.svg"}
            alt=""
          />
        ) : (
          <>
            <DehazeIcon
              fontSize="large"
              className="nav__dehaze"
              onClick={toggleDrawer(true)}
            />
            <img
              className="nav__logo__center"
              src={process.env.PUBLIC_URL + "/Selo_5.svg"}
              alt=""
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            />
          </>
        )}

        <Avatar />
      </div>
      <Drawer open={openSumary} onClose={toggleDrawer(false)}>
        {
          <Sumary
            sections={sections}
            toggleDrawer={toggleDrawer}
            scrollFunc={scrollFunc}
          />
        }
      </Drawer>
    </div>
  )
}

export default Nav
