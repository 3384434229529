import React, { useEffect, useState } from "react"
import FormInput from "../components/FormInput"
import Button from "../components/Button"
import { useSnackbar } from "react-simple-snackbar"
import { errorSnackBar } from "../components/snackBarOptions"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { login } from "../app/features/userSlice"
import requests from "../api/requests"

import { faEnvelope, faLock, faUser } from '@fortawesome/free-solid-svg-icons'

export default function SignUpScreen() {
  const [openErrorSnackbar] = useSnackbar(errorSnackBar)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const location = useLocation()
  const content = location?.state

  useEffect(() => {
    if (!content?.email) {
      navigate("/")
    }
  }, [content])

  const [values, setValues] = useState({
    email: content?.email,
    name : content?.name,
    password: "",
    confirmPassword: "",
  })

  const [isLoading, setIsLoading] = useState(false)

  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "Preencha com um email válido",
      label: "Email",
      required: true,
      disabled: true,
      icon: faEnvelope,
    },
    {
      id: 2,
      name: "name",
      type: "text",
      placeholder: "Nome",
      errorMessage: "O campo nome é obrigatório",
      label: "Nome",
      required: true,
      icon: faUser,
    },
    {
      id: 3,
      name: "password",
      type: "password",
      placeholder: "Senha",
      errorMessage: "Mínimo de 8 caracteres",
      label: "Senha",
      pattern: ".{8,}",
      required: true,
      icon: faLock,
    },
    {
      id: 5,
      name: "confirmPassword",
      type: "password",
      placeholder: "Repita a senha",
      errorMessage: "As senhas não são iguais",
      label: "Repita a senha",
      pattern: values.password ? `^${values.password.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}$` : "",
      required: true,
      icon: faLock,
    },
  ]

  const register = async (e) => {
    e.preventDefault()

    const formElement = e.target
    const isValid = formElement.checkValidity()
    if (!isValid) {
      openErrorSnackbar("Preencha os campos solicitados")
      return
    }
    try {
      setIsLoading(true)
      const isAlumyMember = content?.source === "cosmo_alumy" || content?.isAlumyMember
      const resp = await requests.register({
        name: values.name,
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        isAlumyMember,
      })
      dispatch(login(resp.data))
      navigate("/plan")
    } catch (error) {
      openErrorSnackbar("Email já cadastrado")
    } finally {
      setIsLoading(false)
    }
  }

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const firstName = content?.name?.split(" ")[0]
  const formattedFirstName = firstName ? firstName.charAt(0).toUpperCase() + firstName.slice(1) : ""

  return (
    <div className="onboardingScreen">
      <div className="onboardingScreen__background"></div>
      <div className="onboardingScreen__gradient"></div>
      <div className="onboardingScreen__body">
        <div className="onboardingScreen__form">
        {content?.name ? (
          <>
          <h1>Olá {formattedFirstName}!</h1>
          <h2>Para continuar com o cadastro informe uma senha</h2>
          </>
        ) : (
          <>
          <h1>Olá!</h1>
          <h2>Informe seus dados para criar uma nova conta</h2>
          </>
          )}
          <form onSubmit={register} noValidate autoComplete="off">
            {inputs.map((input) => (
              <FormInput
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            <Button text="Continuar" isLoading={isLoading} />
          </form>
        </div>
      </div>
    </div>
  )
}
