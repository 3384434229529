import React from 'react'
import Button from "../components/Button"
import "./OnboardingScreen.css"
import { useLocation, useNavigate } from 'react-router-dom'

export default function DraftScreen() {
    const location = useLocation()
    const navigate = useNavigate()

    const { title, publish_at } = location.state

    if (!title || !publish_at) {
      navigate("/")
    }
    
    const dateFormatted = new Date(publish_at).toLocaleDateString("pt-BR")

    const backToHome = () => {
      navigate("/")
    }

    return (
        <div className="onboardingScreen">
          <div className="onboardingScreen__background"></div>
          <div className="onboardingScreen__gradient"></div>
          <div className="onboardingScreen__body">
            <div className="unpaidScreen">
              <p><b>{title}</b> ainda não está disponível.</p>
              <p>Volte no dia <b>{dateFormatted}</b> para poder acessar esse conteúdo.</p>
              <Button onClick={e => backToHome()} text="Voltar ao Iníco" />
            </div>
          </div>
        </div>
      )
}
